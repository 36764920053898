<template>
  <nav>
    <div class="nav-container">
      <div class="logo">
        <img src="/img/ingreen.svg" alt="" />
      </div>
      <div @click="mobileNavigation" class="burger">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>

      <ul class="nav-links">
        <li :class="{ active: screenTrue == 'main' }" @click="screen('main')">
          Головна
        </li>
        <li
          :class="{ active: screenTrue == 'catalog' }"
          @click="screen('catalog')"
        >
          Каталог
        </li>
        <li
          :class="{ active: screenTrue == 'contact' }"
          @click="screen('contact')"
        >
          Контакти
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  props: {
    screenTrue: {
      type: String,
      default: "main",
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    mobileNavigation() {
      const menuItems = document.querySelector(".nav-links");
      const burger = document.querySelector(".burger");
      menuItems.classList.toggle("navActive");
      burger.classList.toggle("toggle");
    },
    screen(screenTitle) {
      this.mobileNavigation();
      window.scrollTo(0, 0);
      this.$emit("trueScreen", screenTitle);
    },
  },
};
</script>

<style scoped>
nav {
  position: fixed;
  min-height: 8vh;
  width: 100vw;
  z-index: 9998;
  transition: all 0.3s ease;
  background: #8c9c92;
}
.nav-container {
  width: 100%;
  padding: 0 50px;
  min-height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  position: relative;
  margin: 5px 0;
  z-index: 100000;
}

.burger {
  display: none;
  cursor: pointer;
  z-index: 100000;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.burger:focus {
  outline: none;
}
.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.nav-links li {
  margin: 0 15px;
  transition: all 0.3s ease;
  color: rgb(191, 191, 191);
  font-weight: 700;
}
.nav-links li:hover {
  transition: all 0.3s ease;
  transform: translateY(-5px);
  cursor: pointer;
}
.nav-links a {
  text-decoration: none;
}

.nav-links li {
  list-style: none;
  opacity: 1;
}

.burger > div {
  width: 25px;
  height: 3px;
  background: #fff;
  margin: 5px;
  cursor: pointer;
}
.anime {
  animation: navLinkFade 2s;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.line1,
.line2,
.line3 {
  transition: all 0.5s ease;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 768px) {
  .nav-links {
    padding: 20px 0 0 0;
    position: absolute;
    left: 0;
    height: 100vh;
    top: 0vh;
    background: #8c9c92;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in;
    z-index: 9999;
  }
  .nav-links li {
    margin: 20px 0;
    font-size: 24px;
  }
  .burger {
    display: block;
  }
  .menu-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 50%;
  }
}
.navActive {
  transform: translateX(0%);
  opacity: 1;
}
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.3s ease-out;
}
.active {
  color: rgb(255, 255, 255) !important;
}
</style>
