import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    data: [],
  },
  getters: {
    getAllData(state) {
      return state;
    },
    setByCategory(state, category) {
      return state.data.filter((x) => x.category == category);
    },
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },

    resetData() {
      this.state.data = this.setData();
    },

    setByCategory(state, category) {
      this.state.data = state.data.filter((x) => x.category == category);
    },
  },
  actions: {
    getData(context) {
      fetch("data.json")
        .then((response) => response.json())
        .then((json) => context.commit("setData", json));
    },
  },
  modules: {},
});
