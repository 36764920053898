<template>
  <div>
    <section id="first-screen">
      <div class="main">
        <h1>
          Партнерам <br />
          Компанії <br />
          Ingreen
        </h1>

        <div class="subtitle">Будь собою разом з Ingreen</div>
      </div>
    </section>
    <section id="catalog">
      <div class="category-title">
        <h2>Наша продукція</h2>
      </div>
      <div class="category-wrapper">
        <div class="category">
          <div class="category-item matrasy">
            <a @click="screen('catalog', 'postilni')">
              <div class="category-item-heder">
                <img src="/img/bad-min.jpg" alt="" />
              </div>
              <div class="category-item-title">Постільні речі</div>
              <div class="category-item-more">Детальніше</div>
            </a>
          </div>
          <div class="category-item">
            <a @click="screen('catalog', 'odyag')">
              <div class="category-item-heder">
                <img src="/img/baby-min.jpg" alt="" />
              </div>
              <div class="category-item-title">Одяг для немовлят</div>
              <div class="category-item-more">Детальніше</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Main",
  data: () => ({
    scrollY: window.scrollY,
  }),

  mounted() {
    this.handleScroll();
  },

  methods: {
    handleScroll() {
      let x = window.scrollY;
      this.scrollY = x;
    },
    mobileNavigation() {
      const menuItems = document.querySelector(".nav-links");
      const burger = document.querySelector(".burger");
      menuItems.classList.toggle("navActive");
      burger.classList.toggle("toggle");
    },
    screen(screenTitle, category) {
      if (category == "postilni") {
        window.scrollTo(0, 0);
        this.$emit("trueScreen", screenTitle);
        this.$emit("selectCategory", "postilni");
      } else if (category == "odyag") {
        window.scrollTo(0, 0);
        this.$emit("trueScreen", screenTitle);
        this.$emit("selectCategory", "odyag");
      }
    },
    // selectCategory(arg) {
    //   this.$emit("selectCategory", arg);
    // },
  },
};
</script>

<style scoped>
/* first screen */
#first-screen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  background-image: url("/img/bg-min.jpg");
  background-position: center left;
  background-size: cover;
}
#catalog {
  background: rgba(255, 255, 255, 0.5);
  padding: 0 0 100px 0;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main h1 {
  color: #fff;
  font-size: 46px;
  line-height: 78px;
  padding-left: 100px;
}

.subtitle {
  font-size: 24px;
  padding-left: 100px;
  padding-top: 50px;
  color: #fff;
}

@media (max-width: 425px) {
  #first-screen {
    grid-template-columns: 1fr;
    align-items: center;
  }
  .subtitle {
    padding: 0;
    text-align: center;
  }
  .main h1 {
    padding: 0;
    text-align: center;
  }
}

.category-title h2 {
  padding: 150px 0 20px 0;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
}

.category-subtitle {
  text-align: center;
  font-size: 18px;
  margin-bottom: 100px;
}

.category {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.category-item {
  position: relative;
  width: 300px;
  height: 420px;
  background: #fafafa;
  box-shadow: 10px 8px 15px rgba(140, 156, 146, 0.25);
  border-radius: 10px;
  transition: all 0.3s ease;
  margin: 0 20px;
}
.category-item a {
  text-decoration: none;
  color: inherit;
}
.category-item:hover {
  box-shadow: 10px 8px 15px rgba(140, 156, 146, 0.75);
  transform: scale(1.02);
}
.category-item-heder img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 20px;
}
.category-item-title {
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
}

.category-item-more {
  position: absolute;
  width: 150px;
  bottom: 10px;
  left: calc(50% - 75px);
  text-align: center;
}

@media (max-width: 768px) {
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .category-item {
    margin: 20px 0;
  }
}
</style>
