<template>
  <div class="contact-wrapper container-fluid">
    <div class="contact">
      <div class="develop">
        <svg
          id="Capa_1"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g>
            <g>
              <g>
                <path
                  d="M507.857,227.896L397.19,147.894c-3.043-2.201-7.065-2.509-10.41-0.801c-3.344,1.71-5.449,5.149-5.449,8.905v60.433     l-94.807-68.537c-3.043-2.201-7.064-2.509-10.408-0.801c-3.346,1.71-5.449,5.149-5.449,8.905v60.433l-94.809-68.537     c-3.043-2.201-7.064-2.509-10.41-0.801c-3.344,1.71-5.449,5.149-5.449,8.905v70.001h-20.666L126.184,35.313     c-0.363-5.244-4.721-9.313-9.977-9.313H63.793c-5.256,0-9.613,4.068-9.977,9.313L40.666,226H10c-5.523,0-10,4.477-10,10v240     c0,5.522,4.477,10,10,10h492c5.522,0,10-4.478,10-10V236C512,232.789,510.459,229.775,507.857,227.896z M62.094,205.999h21.34     c5.521,0,10-4.478,10-10c0-5.523-4.479-10-10-10H63.473l9.654-139.998h33.746l12.412,179.998h-58.57L62.094,205.999z      M159.998,266.001h-55.689c-5.521,0-10,4.478-10,10c0,5.522,4.479,10,10,10h55.689V306h-55.689c-5.521,0-10,4.478-10,10     c0,5.523,4.479,10,10,10h55.689v20h-26.725c-5.521,0-10,4.478-10,10c0,5.523,4.479,10,10,10h26.725v19.999h-55.689     c-5.521,0-10,4.478-10,10c0,5.522,4.479,10,10,10h55.689v19.998h-55.689c-5.521,0-10,4.478-10,10c0,5.523,4.479,10,10,10h55.689     v20.002H20v-220h29.963c0.012,0,0.025,0.002,0.037,0.002h79.998c0.008,0.001,0.016,0.001,0.02,0c0.014,0,0.025-0.002,0.039-0.002     h29.941V266.001z M492,465.999H179.998v-230v-60.433l94.809,68.537c3.043,2.201,7.064,2.512,10.41,0.8     c3.344-1.709,5.449-5.148,5.449-8.904v-60.433l94.807,68.536c3.043,2.202,7.064,2.51,10.408,0.801     c3.346-1.709,5.449-5.148,5.449-8.904v-60.433L492,241.109V465.999z"
                />
                <path
                  d="M104.309,366h0.236c5.523,0,10-4.477,10-10c0-5.522-4.477-10-10-10h-0.236c-5.521,0-10,4.478-10,10     C94.309,361.522,98.787,366,104.309,366z"
                />
                <path
                  d="M295.998,305.998h-40.002c-5.523,0-10,4.478-10,10v39.998c0,5.522,4.477,10,10,10h40.002c5.522,0,10-4.478,10-10v-39.998     C305.998,310.477,301.52,305.998,295.998,305.998z M285.998,345.996h-20.002v-19.998h20.002V345.996z"
                />
                <path
                  d="M365.998,315.998v39.998c0,5.522,4.479,10,10,10H416c5.523,0,10-4.478,10-10v-39.998c0-5.522-4.477-10-10-10h-40.002     C370.477,305.998,365.998,310.477,365.998,315.998z M385.998,325.998H406v19.998h-20.002V325.998z"
                />
                <path
                  d="M321.69,425.997h-0.236c-5.521,0-10,4.478-10,10c0,5.523,4.479,10,10,10h0.236c5.523,0,10-4.477,10-10     C331.69,430.475,327.213,425.997,321.69,425.997z"
                />
                <path
                  d="M292.723,425.997h-36.725c-5.523,0-10,4.478-10,10c0,5.523,4.477,10,10,10h36.725c5.521,0,10-4.477,10-10     C302.723,430.475,298.244,425.997,292.723,425.997z"
                />
              </g>
            </g>
          </g>
        </svg>
        <div class="title">Виробництво</div>

        <div class="phone"><a href="tel:+380504656334">+380504656334</a></div>
        <div>Таллінський квартал, Славутич, Україна</div>
      </div>
      <div class="ecommerce">
        <svg
          data-name="Layer 1"
          id="Layer_1"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs></defs>
          <title />
          <path
            class="cls-1"
            d="M2,50a5,5,0,0,0,4,4.9V61a3,3,0,0,0,3,3H53a3,3,0,0,0,3-3V7a3,3,0,0,0-3-3H52V3a3,3,0,0,0-3-3H9A3,3,0,0,0,6,3V7.1a5,5,0,0,0,0,9.8v9.2a5,5,0,0,0,0,9.8v9.2A5,5,0,0,0,2,50ZM53,6a1,1,0,0,1,1,1V61a1,1,0,0,1-1,1H52V6ZM39.5,2h5v9a.6.6,0,0,1-.31.55.42.42,0,0,1-.45,0l-.89-.65a1.44,1.44,0,0,0-1.7,0l-.89.65a.42.42,0,0,1-.45,0A.6.6,0,0,1,39.5,11ZM4,31a3,3,0,0,1,2-2.82V31a1,1,0,0,0,2,0V16.9A5,5,0,0,0,12,12a1,1,0,0,0-2,0A3,3,0,1,1,6,9.18V12a1,1,0,0,0,2,0V3A1,1,0,0,1,9,2h5V5a1,1,0,0,0,2,0V2H38.5v9a1.61,1.61,0,0,0,.85,1.44,1.41,1.41,0,0,0,1.5-.13l.89-.65a.45.45,0,0,1,.52,0l.89.65a1.43,1.43,0,0,0,.85.29,1.39,1.39,0,0,0,.65-.16A1.61,1.61,0,0,0,45.5,11V2H49a1,1,0,0,1,1,1V62H9a1,1,0,0,1-1-1V54.9A5,5,0,0,0,12,50a1,1,0,0,0-2,0,3,3,0,1,1-4-2.82V50a1,1,0,0,0,2,0V35.9A5,5,0,0,0,12,31a1,1,0,0,0-2,0,3,3,0,0,1-6,0Z"
          />
          <path
            class="cls-1"
            d="M15,22a1,1,0,0,0,1-1V17a1,1,0,0,0-2,0v4A1,1,0,0,0,15,22Z"
          />
          <path
            class="cls-1"
            d="M15,14a1,1,0,0,0,1-1V9a1,1,0,0,0-2,0v4A1,1,0,0,0,15,14Z"
          />
          <path
            class="cls-1"
            d="M15,46a1,1,0,0,0,1-1V41a1,1,0,0,0-2,0v4A1,1,0,0,0,15,46Z"
          />
          <path class="cls-1" d="M16,61V57a1,1,0,0,0-2,0v4a1,1,0,0,0,2,0Z" />
          <path
            class="cls-1"
            d="M15,30a1,1,0,0,0,1-1V25a1,1,0,0,0-2,0v4A1,1,0,0,0,15,30Z"
          />
          <path
            class="cls-1"
            d="M15,54a1,1,0,0,0,1-1V49a1,1,0,0,0-2,0v4A1,1,0,0,0,15,54Z"
          />
          <path
            class="cls-1"
            d="M15,38a1,1,0,0,0,1-1V33a1,1,0,0,0-2,0v4A1,1,0,0,0,15,38Z"
          />
          <path
            class="cls-1"
            d="M33,35a8,8,0,1,0-8-8A8,8,0,0,0,33,35Zm0-14a6,6,0,1,1-6,6A6,6,0,0,1,33,21Z"
          />
          <path
            class="cls-1"
            d="M29.5,27A3.5,3.5,0,0,1,33,23.5a.5.5,0,0,0,0-1A4.51,4.51,0,0,0,28.5,27a.5.5,0,0,0,1,0Z"
          />
          <path
            class="cls-1"
            d="M22,47H44a3,3,0,0,0,3-3V39.12a4,4,0,0,0-2.46-3.69,16.06,16.06,0,0,1-2.44-1.26,2,2,0,0,0-2.41.25,10,10,0,0,1-13.38,0,2,2,0,0,0-2.41-.25,16.06,16.06,0,0,1-2.44,1.26A4,4,0,0,0,19,39.12V44A3,3,0,0,0,22,47Zm-1-7.88a2,2,0,0,1,1.23-1.84A22.64,22.64,0,0,0,25,35.9a11.92,11.92,0,0,0,16.07,0,18.51,18.51,0,0,0,2.73,1.41h0A2,2,0,0,1,45,39.12V44a1,1,0,0,1-1,1H22a1,1,0,0,1-1-1Z"
          />
          <path
            class="cls-1"
            d="M42,50.5H24a.5.5,0,0,0,0,1H42a.5.5,0,0,0,0-1Z"
          />
          <path
            class="cls-1"
            d="M38,54.5H28a.5.5,0,0,0,0,1H38a.5.5,0,0,0,0-1Z"
          />
        </svg>
        <div class="title">Відділ продажу</div>
        <div class="phone"><a href="tel:+380985556153">+380985556153</a></div>
        <div>вул. Скорини, 44, Львів, Україна</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped>
.contact-wrapper {
  padding: 12vh 20px 0 20px;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.contact {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: auto 0;
}
.develop,
.ecommerce {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.develop > div,
.ecommerce > div {
  margin-top: 20px;
}
.develop svg,
.ecommerce svg {
  width: 120px;
  fill: rgb(121, 139, 128);
}
.develop,
.ecommerce {
  width: 500px;
  background: rgba(255, 255, 255, 0.8);
  padding: 50px 0;
  border-radius: 15px;
  transition: all 0.3s ease;
}
.develop:hover,
.ecommerce:hover {
  transform: scale(1.02);
}
.title {
  font-size: 24px;
  font-weight: 600;
}
.phone {
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone svg {
  width: 22px;
  margin-right: 7px;
}
.phone a {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}
@media (max-width: 1099px) {
  .contact {
    flex-direction: column;
    justify-content: center;
  }
  .contact-wrapper {
    height: 100%;
    width: 100%;
    padding: 20vh 0;
  }
  .contact {
    height: 100%;
  }
  .develop,
  .ecommerce {
    margin: 20px 0;
    width: 500px;
    text-align: center;
  }
}
@media (max-width: 550px) {
  .develop,
  .ecommerce {
    width: 90%;
    text-align: center;
  }
}
</style>
