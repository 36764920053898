<template>
  <div class="catalog-wrapper">
    <div class="catalog">
      <div class="header">
        <div class="select-category-wrapper">
          <div class="select-category">Оберіть категорію</div>
          <select v-model="searchItem" name="category">
            <optgroup label="Постільні речі">
              <option value="постільне">Всі постільні</option>
              <option value="матраци">Матраци</option>
              <option value="наматрацники">Наматрацники</option>
              <option value="постільні-комплекти">
                Комплект постільної білизни
              </option>
              <option value="набір для дитячого ліжка з захистом">
                Набір для дитячого ліжка з захистом
              </option>
              <option value="простирадла">Простирадла</option>
              <option value="подушки">Подушки</option>
              <option value="ковдри">Ковдри</option>
              <option value="пелюшка-ковдра">Пелюшка-ковдра</option>
              <option value="рушник">Рушники для купання</option>
              <option value="плед з велсофту">Плед з велсофту</option>
            </optgroup>
            <optgroup label="Одяг для немовлят">
              <option value="одяг">Весь одяг</option>
              <option value="комбінезон">Комбінезон</option>
              <option value="боді">Боді</option>
              <option value="повзуни">Повзуни</option>
              <option value="комплект">Комплекти</option>
              <option value="пелюшка">Пелюшка</option>
              <option value="шапочка">Шапочка</option>
              <option value="пінетки">Пінетки</option>
              <option value="рукавички">Рукавички</option>
              <option value="комбінезони теплі">Комбінезони теплі</option>
              <option value="шапочка тепла">Шапочка тепла</option>
              <option value="пінетки теплі">Пінетки теплі</option>
              <option value="рукавички теплі">Рукавички теплі</option>
              <option value="костюми теплі">Костюми теплі</option>
              <option value="комплекти (шапка та пінетки) теплі">
                Комплекти (шапка та пінетки) теплі
              </option>
            </optgroup>
            <option value="матраци для тварин">Матраци для тварин</option>
          </select>
        </div>
        <a class="btn" href="/catalog.xlsx">
          Завантажити каталог для інтернет-магазину
        </a>
      </div>
      <div class="table title">
        <div>Артикул</div>
        <div>Штрих код</div>
        <div class="name">Назва товару</div>
        <div>ОПТ</div>
        <div>РРЦ</div>
      </div>
      <div>
        <div class="table" v-for="(item, idx) in list" :key="idx">
          <div>{{ item.id }}</div>
          <div class="barcode">{{ item.barcode }}</div>
          <div class="name">{{ item.name }}</div>
          <div>{{ item.dealer }}</div>
          <div>{{ item.rrc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Catalog",
  props: {
    subcategory: {
      type: String,
      default: "postilni",
    },
  },
  watch: {
    subcategory: function () {
      if (this.subcategory === "postilni") {
        this.getFilterCategory("постільне");
      } else if (this.subcategory === "odyag") {
        this.getFilterCategory("одяг");
      }
    },
    searchItem: function (category) {
      if (category !== "постільне" && category !== "одяг") {
        this.getFilterSubCategory(category);
      } else this.getFilterCategory(category);
    },
  },
  mounted() {
    fetch("data.json")
      .then((response) => response.json())
      .then((json) => (this.fetchList = json));
  },
  data() {
    return {
      searchItem: "",
      fetchList: [],
      list: [],
    };
  },
  methods: {
    getFilterSubCategory(category) {
      let arr = [];
      arr = this.fetchList.filter((item) => item.sub_category === category);
      this.list = arr;
    },
    getFilterCategory(category) {
      let arr = [];
      arr = this.fetchList.filter((item) => item.main_category === category);
      this.list = arr;
    },
  },
};
</script>

<style scoped>
.catalog-wrapper {
  width: 100%;
  height: 100vh;
  padding: 100px 20px;
}
.header {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header > div {
  padding-right: 10px;
}
.select-category-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-category-wrapper > div {
  padding-right: 10px;
}
select {
  height: 30px;
  border-radius: 7px;
}
.catalog {
  background: rgba(255, 255, 255, 0.7);
}
.table-title {
  display: flex;
}
.title {
  background-color: rgb(121, 139, 128);
  color: #f7f7f7;
}
.table {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.table > div {
  border: 1px solid silver;
  padding: 5px;
}

.btn {
  display: flex;
  align-items: center;
  height: 30px;
  background: rgb(121, 139, 128);
  outline: none;
  border-radius: 7px;
  padding: 0 20px;
  color: #f7f7f7;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 0 10px;
  font-size: 12px;
  text-decoration: none;
}
.btn:hover {
  transform: scale(1.02);
}
@media (max-width: 700px) {
  .catalog-wrapper {
    padding: 100px 0;
  }
  .table > div {
    font-size: 14px;
  }
  .barcode {
    font-size: 8px !important;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .header > div {
    margin-bottom: 10px;
  }
  .btn {
    font-size: 12px;
    margin: 15px 0;
    padding: 17px 20px;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .table > div {
    font-size: 10px;
  }
  select {
    width: 200px;
  }
  .name {
    min-width: 100px;
  }
}
</style>
