<template>
  <div id="app">
    <Nav :screenTrue="screen" @trueScreen="trueScreen" />
    <Main
      @selectCategory="selectCategory"
      @trueScreen="trueScreen"
      v-show="screen === 'main'"
    />
    <Contact v-show="screen == 'contact'" />
    <Catalog :subcategory="subcategory" v-show="screen == 'catalog'" />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Main from "./components/Main.vue";
import Contact from "./components/Contact.vue";
import Catalog from "./components/Catalog.vue";

export default {
  name: "App",
  components: {
    Nav,
    Main,
    Contact,
    Catalog,
  },
  data: () => ({
    screen: "main",
    subcategory: "",
  }),
  methods: {
    trueScreen(screenItem) {
      this.screen = screenItem;
    },
    selectCategory(arg) {
      this.subcategory = arg;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #3838ca84;
  --text-color: rgb(31, 31, 31);
  --star-color: #ede619;
  --body-color-1: #65dfc9;
  --body-color-2: #6cdbeb;
  --white-opasity70: rgba(255, 255, 255, 0.7);
  --white-opasity30: rgba(255, 255, 255, 0.3);
  --white-opasity90: rgba(255, 255, 255, 0.9);
}
body {
  background: rgb(121, 139, 128);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  width: 100%;
}

.star {
  display: flex;
  justify-content: center;
  align-items: center;
}

.star img {
  width: 30px;
  height: 30px;
}
.active img {
  opacity: 1;
}
</style>
